import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from './../constants'

export const useStyles = makeStyles(() => ({
  pageWrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  spaceBetween: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100vh - 49px)',
  },
  mainContainer: {
    paddingBottom: 50,
    overflowX: 'hidden',
    height: 'calc(100vh - 200px)', // where 50px is the height of the header
  },
  scrollableContainer: {
    paddingBottom: 0,
    overflowX: 'hidden',
    flex: '1 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
  },
  [`@media(min-width: ${BREAKPOINTS.md}px)`]: {
    mainContainer: {
      paddingBottom: 100,
    },
  },
}))
